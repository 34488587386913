import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { customFormat } from '../../../../utils/numbers';

const { width, height } = configs;
const largeBlackBold = { fontSize: fontSizes.large, color: colors.black, fontFamily: fontFamilies.bold };
const lineGap = 100;
class PopupPrizePool extends Popup {
  constructor(scene, { isSimulator } = {}) {
    super(scene, 'popup-small', { title: 'Total Prize Pool', titleIcon: 'icon-info', noCloseBtn: true });

    const events = {
      requestPrizePoolInfo: isSimulator ? 'simulator-request-prize-pool-info' : 'request-prize-pool-info',
      updatePrizePoolInfo: isSimulator ? 'simulator-update-prize-pool-info' : 'update-prize-pool-info',
    };

    const leftMargin = this.popup.x - this.popup.width / 2;
    const paddedX = leftMargin + this.popup.width * 0.1;
    const startingY = this.popup.y - this.popup.height / 2;
    const firstParagraphY = startingY + 220;

    this.line1 = scene.add.text(
      paddedX,
      firstParagraphY,
      '-- $GREED + --% of purchases.\nVests over -- days.',
      largeBlackBold
    );
    this.line2 = scene.add.text(
      paddedX,
      this.line1.y + this.line1.height + lineGap,
      '--% of $GREED trading fees.',
      largeBlackBold
    );
    this.line3 = scene.add.text(
      paddedX,
      this.line2.y + this.line2.height + lineGap,
      '-- $xU (--%) from Uncharted\nfoundation.',
      largeBlackBold
    );
    this.line4 = scene.add.text(
      paddedX,
      this.line3.y + this.line3.height + lineGap,
      '-- Gold from Blast.',
      largeBlackBold
    );
    this.add(this.line1);
    this.add(this.line2);
    this.add(this.line3);
    this.add(this.line4);

    this.buttonBack = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        if (this.table) {
          this.table.setMouseWheelScrollerEnable(false);
        }
        this.close();
        scene.popupLeaderboard.open();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.buttonBack);

    scene.game.events.on(events.updatePrizePoolInfo, ({ prizePoolConfig, xUPool, blastGoldReward }) => {
      const {
        prizePoolTokenBase,
        tokenTaxToPrizePoolPercent,
        unitSpendToPrizePoolPercent,
        tokenPrizeVestDuration,
        totalUnchartedXU,
      } = prizePoolConfig;
      const xUPercent = xUPool / totalUnchartedXU;
      this.line1.text = `${customFormat(prizePoolTokenBase, 2)} $GREED + ${
        unitSpendToPrizePoolPercent * 100
      }% of purchases.\nRank rewards vest over ${tokenPrizeVestDuration} days.`;
      this.line2.text = `${tokenTaxToPrizePoolPercent * 100}% of $GREED trading fees.`;
      this.line3.text = `${customFormat(xUPool || 0, 2)} $xU (${xUPercent * 100}%) from Uncharted\nfoundation.`;
      this.line4.text = `${customFormat(blastGoldReward || 0, 2)} Gold from Blast.`;
    });
    scene.game.events.emit(events.requestPrizePoolInfo);
  }
}

export default PopupPrizePool;
