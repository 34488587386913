import Popup from './Popup';
import TextButton from '../button/TextButton';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import configs from '../../configs/configs';

const { width, height } = configs;
const buttonWidth = 506;
const btnGap = 50;

class PopupConfirmBidding extends Popup {
  biddingData = null;

  constructor(scene) {
    super(scene, 'popup-small', { title: 'Bid confirmation' });

    this.noBtn = new TextButton(
      scene,
      width / 2 - buttonWidth / 2 - btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
        scene.popupAuctionBidding?.open();
      },
      'No',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.noBtn);

    this.confirmBtn = new TextButton(
      scene,
      width / 2 + buttonWidth / 2 + btnGap / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        this.showLoading();
        scene.game.events.emit('bid', {
          itemId: this.biddingData.item.id,
          quantity: this.biddingData.quantity,
          value: this.biddingData.value,
        });
      },
      'Yes',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.add(this.confirmBtn);

    this.nameText = scene.add
      .text(this.popup.x, this.popup.y - this.popup.height / 2 + 160, '', {
        fontSize: '72px',
        fontFamily: fontFamilies.extraBold,
        color: '#7d2e00',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.nameText);

    this.image = scene.add.sprite(this.popup.x, this.nameText.y + 60, 'greed-prize-4').setOrigin(0.5, 0);
    this.add(this.image);

    const valueY = this.image.y + this.image.height + 60;
    this.icon = scene.add.sprite(this.popup.x, valueY, 'icon-coin-small').setOrigin(0.5, 0.5);
    this.valueText = scene.add
      .text(this.popup.x, valueY, '0', { fontSize: '72px', fontFamily: fontFamilies.extraBold, color: '#7d2e00' })
      .setOrigin(0.5, 0.5);
    this.icon.x = this.popup.x - (this.icon.width + this.valueText.width + 20) / 2 + this.icon.width / 2;
    this.valueText.x = this.icon.x + this.icon.width / 2 + 20 + this.valueText.width / 2;
    this.add(this.icon);
    this.add(this.valueText);

    const bidAmountY = valueY + 100;
    this.bidAmountText = scene.add
      .text(this.popup.x, bidAmountY, `Amount per Bid: -`, {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000b',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.xIcon = scene.add.image(this.popup.x, bidAmountY, 'icon-xgang-small').setOrigin(0.5, 0.5);

    this.bidAmountText.x =
      this.popup.x - (this.bidAmountText.width + this.xIcon.width + 20) / 2 + this.bidAmountText.width / 2;
    this.xIcon.x = this.bidAmountText.x + this.bidAmountText.width / 2 + 20 + this.xIcon.width / 2;
    this.add(this.bidAmountText);
    this.add(this.xIcon);

    this.numberOfBidsText = scene.add
      .text(this.popup.x, this.xIcon.y + 60, `# Bids: -`, {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000b',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfBidsText);

    this.totalText = scene.add
      .text(this.popup.x, this.numberOfBidsText.y + 60, `Total: -`, {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000b',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);

    this.xIcon2 = scene.add.image(this.popup.x, this.numberOfBidsText.y + 60, 'icon-xgang-small').setOrigin(0.5, 0.5);

    this.totalText.x = this.popup.x - (this.totalText.width + this.xIcon2.width + 20) / 2 + this.totalText.width / 2;
    this.xIcon2.x = this.totalText.x + this.totalText.width / 2 + 20 + this.xIcon2.width / 2;

    this.add(this.totalText);
    this.add(this.xIcon2);

    this.text = scene.add
      .text(this.popup.x, this.totalText.y + 100, 'Are you sure?\nThis is non-refundable.', {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000b',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.text);

    this.loadingBanner = scene.add
      .image(this.popup.x, this.popup.y, 'loading-banner')
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.loadingIcon = scene.add
      .image(this.loadingBanner.x - this.loadingBanner.width / 2 + 130, this.popup.y, 'icon-loading-small')
      .setVisible(false);
    this.loadingText = scene.add
      .text(this.loadingIcon.x + this.loadingIcon.width / 2 + 40, this.popup.y, 'Creating Bid...', {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, 0.5)
      .setVisible(false);
    this.add(this.loadingBanner);
    this.add(this.loadingIcon);
    this.add(this.loadingText);

    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();

    scene.game.events.on('bid-success', () => {
      this.hideLoading();
      this.close();
      scene.popupBiddingDone?.show(this.biddingData);
    });
    scene.game.events.on('bid-error', (err) => {
      this.hideLoading();
      this.close();
      scene.popupBiddingDone?.show(this.biddingData, err);
    });
  }

  show(biddingData) {
    this.biddingData = biddingData;

    const { name, value, valueImg, iconImg } = biddingData.item;

    this.image.setTexture(iconImg);
    this.nameText.text = name;
    this.icon.setTexture(valueImg);
    this.valueText.text = formatter.format(value);
    this.icon.x = this.popup.x - (this.icon.width + this.valueText.width + 20) / 2 + this.icon.width / 2;
    this.valueText.x = this.icon.x + this.icon.width / 2 + 20 + this.valueText.width / 2;

    this.bidAmountText.text = `Amount per Bid: ${formatter.format(biddingData.value)}`;
    this.bidAmountText.x =
      this.popup.x - (this.bidAmountText.width + this.xIcon.width + 20) / 2 + this.bidAmountText.width / 2;
    this.xIcon.x = this.bidAmountText.x + this.bidAmountText.width / 2 + 20 + this.xIcon.width / 2;
    this.numberOfBidsText.text = `# Bids: ${biddingData.quantity}`;

    this.totalText.text = `Total: ${formatter.format(biddingData.value * biddingData.quantity)}`;
    this.totalText.x = this.popup.x - (this.totalText.width + this.xIcon2.width + 20) / 2 + this.totalText.width / 2;
    this.xIcon2.x = this.totalText.x + this.totalText.width / 2 + 20 + this.xIcon2.width / 2;

    this.open();
  }

  showLoading() {
    this.loading = true;
    this.confirmBtn.setDisabledState(true);
    this.loadingBanner.setVisible(true);
    this.loadingIcon.setVisible(true);
    this.loadingText.setVisible(true);
    this.loadingAnimation.resume();
    this.nameText.setAlpha(0.5);
    this.icon.setAlpha(0.5);
    this.valueText.setAlpha(0.5);
    this.bidAmountText.setAlpha(0.5);
    this.xIcon.setAlpha(0.5);
    this.numberOfBidsText.setAlpha(0.5);
    this.totalText.setAlpha(0.5);
    this.text.setAlpha(0.5);
  }

  hideLoading() {
    this.loading = false;
    this.confirmBtn.setDisabledState(false);
    this.loadingBanner.setVisible(false);
    this.loadingIcon.setVisible(false);
    this.loadingText.setVisible(false);
    this.loadingAnimation.pause();
    this.nameText.setAlpha(1);
    this.icon.setAlpha(1);
    this.valueText.setAlpha(1);
    this.bidAmountText.setAlpha(1);
    this.xIcon.setAlpha(1);
    this.numberOfBidsText.setAlpha(1);
    this.totalText.setAlpha(1);
    this.text.setAlpha(1);
  }
}

export default PopupConfirmBidding;
