import api from './api';

export const getMe = () => api.get('/api/v1/users/me');

export const getByCode = (code) => api.get('/api/v1/users', { params: { code } });

export const applyInviteCode = (data) => api.put('/api/v1/users/me/invite-code', data);

export const getWarHistory = () => api.get('/api/v1/users/me/war-history');

export const getWarHistoryDetail = ({ warSnapshotId, warResultId }) =>
  api.get(`/api/v1/users/me/war-history/${warSnapshotId}/${warResultId}`);

export const completeAskingWalletPassword = () => api.put('/api/v1/users/me/wallet-password-ask');

export const updateBalance = () => api.put('/api/v1/users/me/balances');

export const getRank = () => api.get('/api/v1/users/me/rank');

export const setLastOnlineTime = () => api.put('/api/v1/users/me/last-online-time');

export const completeTutorial = () => api.put('/api/v1/users/me/tutorial');

export const getUserReferralCode = () => api.get('/api/v1/users/code');

export const unlinkTwitter = () => api.put('/api/v1/users/me/unlink-twitter');

export const doneAskUserToConnectTwitter = () => api.put('/api/v1/users/me/ask-connect-twitter');

export const getXCsrfToken = () => api.post('/api/v1/users/me/x-csrf-token');

export const getAirdropClaimStatus = () => api.get('/api/v1/users/me/airdrop-status');

export const shareAirdrop = () => api.put('/api/v1/users/me/airdrop-shared');

export const claimAirdrop = () => api.put('/api/v1/users/me/airdrop-claimed');

export const getReward = () => api.get('/api/v1/users/me/reward');

export const agreeToS = () => api.put('/api/v1/users/me/tos');
