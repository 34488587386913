import Phaser from 'phaser';

import TutorialCharacter from './TutorialCharacter';
import Button from '../button/Button';
import configs from '../../configs/configs';

const { width, height } = configs;

const y = 2600;

class Step4 extends Phaser.GameObjects.Container {
  numOfCoins = 20;

  constructor(scene, onNext) {
    super(scene, 0, 0);
    this.coinIcon = scene.add.image(width / 2, y, 'icon-xcoin-glowing');

    this.setVisible(false);

    this.character = new TutorialCharacter(scene, width / 2, height - 1100, 'tutorial-5', () => {});
    this.add(this.character);

    this.claimButton = new Button(
      scene,
      width / 2,
      y,
      'tutorial-5-claim-btn',
      'tutorial-5-claim-btn',
      () => {
        this.startCoinAnimation();
        scene.tutorial.setVisible(false);
        setTimeout(() => {
          scene.game.events.emit('simulator-claim-completed', { amount: 15000 });
          setTimeout(() => {
            scene.tutorial.setVisible(true);
            onNext();
          }, 800);
        }, 2400);
      },
      { sound: 'claim-3' }
    );
    this.add(this.claimButton);

    this.arrow = scene.add.image(width / 2, 2500, 'tutorial-arrow-down').setOrigin(0.5, 1);
    this.add(this.arrow);
  }

  startCoinAnimation() {
    const duration = 2400;

    for (let i = 0; i < this.numOfCoins; i++) {
      const path = generateRandomPath();
      const curve = new Phaser.Curves.Spline(path.map((p) => new Phaser.Math.Vector2(p.x, p.y)));
      const pathXs = path.map(({ x }) => x);
      const pathYs = path.map(({ y }) => y);

      setTimeout(() => {
        const emitter = this.scene.add.particles(0, 0, 'flare', {
          blendMode: 'ADD',
          lifespan: 500,
          scale: { start: 1, end: 0 },
        });
        const coinIcon = this.scene.add.image(width / 2, this.y, 'icon-xcoin-glowing');
        this.scene.tweens.add({
          targets: coinIcon,
          x: pathXs,
          y: pathYs,
          duration,
          ease: 'Cubic.out',
          interpolation: 'bezier', // turn the points into curve
          onUpdate: function (tween) {
            const t = tween.progress;
            const position = curve.getPoint(t);
            coinIcon.setPosition(position.x, position.y);
          },
          hideOnComplete: true,
        });

        emitter.startFollow(coinIcon);

        setTimeout(() => {
          coinIcon.destroy();
          emitter.destroy();
        }, duration);
      }, Math.random() * 1000);
    }
  }
}

const numPoints = 7;
const yStart = 2600;
const yEnd = 250;
const xStart = width / 2 + 80;
const xEnd = width / 2 - 580;
const xVariance = 400;
const yVariance = 50;

const generateRandomPath = () => {
  let path = [];

  // Start point
  path.push({ x: xStart, y: yStart });

  // Generate intermediate points
  for (let i = 1; i < numPoints - 1; i++) {
    let x = xStart + (xEnd - xStart) * (i / (numPoints - 1));
    let y = yStart + (yEnd - yStart) * (i / (numPoints - 1));

    // Add random variance
    x += (Math.random() * 2 - 1) * xVariance;
    y += (Math.random() * 2 - 1) * yVariance;

    path.push({ x, y });
  }

  // End point
  path.push({ x: xEnd, y: yEnd });

  return path;
};

export default Step4;
