import Phaser from 'phaser';

import TutorialCharacter from './TutorialCharacter';
import Button from '../button/Button';
import configs from '../../configs/configs';

const { width, height } = configs;

const px = 40;
const buttonWidth = 288;
const y = 2600;

class Step8 extends Phaser.GameObjects.Container {
  clicked = false;

  constructor(scene, onNext) {
    super(scene, 0, 0);

    this.setVisible(false);
    this.onNext = onNext;

    this.character = new TutorialCharacter(scene, width / 2, height / 2 - 400, 'tutorial-8', () => {});
    this.add(this.character);

    this.activeButton = new Button(
      scene,
      width - px - buttonWidth / 2,
      y,
      'button-buy',
      'button-buy-pressed',
      () => {},
      { sound: 'button-1' }
    );
    this.add(this.activeButton);

    this.arrow = scene.add.image(width - 360, 1620, 'tutorial-arrow-right').setOrigin(1, 0);
    this.add(this.arrow);
  }

  start() {
    this.setVisible(true);
    this.scene.popupBuy.updateCallback(() => {
      this.scene.popupBuy.setVisible(false);
      this.onNext();
    });
  }
}

export default Step8;
