import Phaser from 'phaser';

import Button from '../button/Button';
import { fontFamilies } from '../../../../utils/styles';
import configs from '../../configs/configs';

const { width, height } = configs;

class AuctionHeader extends Phaser.GameObjects.Container {
  constructor(scene) {
    super(scene, 0, 0);

    const titleY = height * 0.06;

    this.backBtn = new Button(
      scene,
      width * 0.07,
      titleY,
      'button-auction-back',
      'button-auction-back-pressed',
      () => {
        scene.scene.pause('AuctionScene');
        scene.scene.resume('MainScene');
        scene.scene.bringToTop('MainScene');
      },
      { sound: 'button-1' }
    );
    this.add(this.backBtn);

    this.title = scene.add
      .text(width / 2, titleY, 'Auction House', {
        fontSize: '110px',
        fontFamily: fontFamilies.extraBold,
        color: '#ffffff',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.title);

    this.icon = scene.add.image(width * 0.93, titleY, 'auction-house').setOrigin(0.5, 0.5);
    this.add(this.icon);
  }
}

export default AuctionHeader;
