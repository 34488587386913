import Popup from './Popup';
import TextButton from '../button/TextButton';
import { fontFamilies } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import configs from '../../configs/configs';

const { width, height } = configs;

class PopupBiddingDone extends Popup {
  biddingData = null;

  constructor(scene) {
    super(scene, 'popup-spin', { title: 'Completed', noCloseBtn: true });

    this.buttonGreat = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      this.close,
      'Great',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.buttonGreat);

    this.nameText = scene.add
      .text(this.popup.x, this.popup.y - this.popup.height / 2 + 160, '', {
        fontSize: '72px',
        fontFamily: fontFamilies.extraBold,
        color: '#7d2e00',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.nameText);

    this.image = scene.add.sprite(this.popup.x, this.nameText.y + 60, 'greed-prize-4').setOrigin(0.5, 0);
    this.add(this.image);

    const valueY = this.image.y + this.image.height + 60;
    this.icon = scene.add.sprite(this.popup.x, valueY, 'icon-coin-small').setOrigin(0.5, 0.5);
    this.valueText = scene.add
      .text(this.popup.x, valueY, '0', { fontSize: '72px', fontFamily: fontFamilies.extraBold, color: '#7d2e00' })
      .setOrigin(0.5, 0.5);
    this.icon.x = this.popup.x - (this.icon.width + this.valueText.width + 20) / 2 + this.icon.width / 2;
    this.valueText.x = this.icon.x + this.icon.width / 2 + 20 + this.valueText.width / 2;
    this.add(this.icon);
    this.add(this.valueText);

    const bidAmountY = valueY + 120;
    this.bidAmountText = scene.add
      .text(this.popup.x, bidAmountY, `Bid amount: -`, {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000b',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.xIcon = scene.add.image(this.popup.x, bidAmountY, 'icon-xgang-small').setOrigin(0.5, 0.5);

    this.bidAmountText.x =
      this.popup.x - (this.bidAmountText.width + this.xIcon.width + 20) / 2 + this.bidAmountText.width / 2;
    this.xIcon.x = this.bidAmountText.x + this.bidAmountText.width / 2 + 20 + this.xIcon.width / 2;
    this.add(this.bidAmountText);
    this.add(this.xIcon);

    this.numberOfBidsText = scene.add
      .text(this.popup.x, this.xIcon.y + 60, `# Bids: -`, {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#29000b',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfBidsText);

    const descriptionY = this.numberOfBidsText.y + 200;
    this.descriptionContainer = scene.add.image(this.popup.x, descriptionY, 'text-container');
    this.description = scene.add
      .text(this.popup.x, descriptionY, 'Bid Successful', {
        fontSize: '52px',
        color: '#7c2828',
        fontFamily: 'WixMadeforDisplayBold',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.descriptionContainer);
    this.add(this.description);
  }

  show(biddingData, err) {
    this.biddingData = biddingData;

    const { name, value, valueImg, iconImg } = biddingData.item;

    this.image.setTexture(iconImg);
    this.nameText.text = name;
    this.icon.setTexture(valueImg);
    this.valueText.text = formatter.format(value);
    this.icon.x = this.popup.x - (this.icon.width + this.valueText.width + 20) / 2 + this.icon.width / 2;
    this.valueText.x = this.icon.x + this.icon.width / 2 + 20 + this.valueText.width / 2;

    this.bidAmountText.text = `Bid amount: ${biddingData.value}`;
    this.bidAmountText.x =
      this.popup.x - (this.bidAmountText.width + this.xIcon.width + 20) / 2 + this.bidAmountText.width / 2;
    this.xIcon.x = this.bidAmountText.x + this.bidAmountText.width / 2 + 20 + this.xIcon.width / 2;
    this.numberOfBidsText.text = `# Bids: ${biddingData.quantity}`;

    if (err) {
      this.description.text = err;
      this.setTitle('Error');
      this.buttonGreat.updateText('Back');
      this.scene.popupAuctionBidding?.reset?.();
    } else {
      this.description.text = 'Bid Successful';
      this.setTitle('Completed');
      this.buttonGreat.updateText('Great');
      this.scene.popupAuctionBidding?.reset?.();
    }

    this.open();
  }
}

export default PopupBiddingDone;
