import Popup from './Popup';
import PopupProcessing from './PopupProcessing';
import PopupConfirm, { icon1Gap } from './PopupConfirm';
import TextButton from '../button/TextButton';
import Button from '../button/Button';
import configs from '../../configs/configs';
import { estimateNumberOfBuildingCanBuy, calculateNextBuildingBuyPriceBatch } from '../../../../utils/formulas';
import { customFormat, formatter } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;
const DEFAULT_QUANTITY = 1;
const INTERVAL = 100;
const largeBlackExtraBold = {
  fontSize: fontSizes.large,
  color: colors.black,
  fontFamily: fontFamilies.extraBold,
};

class PopupSafeHouseUpgrade extends Popup {
  gas = 0;
  numberOfBuildings = 0;
  building = {};
  networth = 0;
  networthBase = 0;
  networthPerDay = 0;
  balance = 0;
  xTokenBalance = 0;
  basePrice = 0;
  maxPerBatch = 10;
  targetDailyPurchase = 1;
  pricePower = 0;
  targetPrice = 0;
  totalSold = 0;
  days = 1;
  quantity = DEFAULT_QUANTITY;
  onCompleted;
  isSimulator = false;
  purchaseToken = 'xGREED';
  isTrackingSales = false;
  started = false;
  startingPrice = 0;

  constructor(scene, { isSimulator, onCompleted, ...configs } = {}) {
    super(scene, 'popup-safehouse-upgrade', { title: 'Buy Safehouse', ...configs });
    this.scene = scene;
    const events = {
      completed: isSimulator ? 'simulator-upgrade-safehouse-completed' : 'upgrade-safehouse-completed',
      updateNetworth: isSimulator ? 'simulator-update-networth' : 'update-networth',
      requestNetworth: isSimulator ? 'simulator-request-networth' : 'request-networth',
      upgradeHouse: isSimulator ? 'simulator-upgrade-safehouse' : 'upgrade-safehouse',
      gameEnded: isSimulator ? 'simulator-game-ended' : 'game-ended',
      updateBuildings: isSimulator ? 'simulator-update-buildings' : 'update-buildings',
      requestBuildings: isSimulator ? 'simulator-request-buildings' : 'request-buildings',
      enableSalesTracking: isSimulator ? 'simulator-enable-building-sales-tracking' : 'enable-building-sales-tracking',
      disableSalesTracking: isSimulator
        ? 'simulator-disable-building-sales-tracking'
        : 'disable-building-sales-tracking',
      updateXTokenBalance: isSimulator ? 'simulator-update-xtoken-balance' : 'update-xtoken-balance',
    };
    this.events = events;
    this.onCompleted = onCompleted;
    this.isSimulator = isSimulator;
    const networthY = this.popup.y - 20;
    const checkBoxY = this.popup.y + 380;
    const leftCheckBoxX = this.popup.x - this.popup.width / 2 + 180;
    const rightCheckBoxX = this.popup.x + 72;
    const availableY = checkBoxY + 50;
    const counterY = this.popup.y + this.popup.height / 2 - 255;
    const minusBtnX = this.popup.x - this.popup.width / 2 + 320;

    this.popupBuyProcessing = new PopupProcessing(scene, {
      sound: 'house',
      completedEvent: events.completed,
      completedIcon: 'icon-safehouse-upgrade-done',
      failedIcon: 'icon-safehouse-upgrade-fail',
      description: `Buying Safehouse.\nPlease, wait`,
      onCompleted,
    });
    scene.add.existing(this.popupBuyProcessing);
    this.popupConfirm = new PopupConfirm(scene, this, {
      title: 'Buy Safehouse',
      action: 'buy',
      icon1: 'icon-safehouse-medium',
      icon2: 'icon-xgang-small',
      onConfirm: () => {
        if (!this.quantity) return;
        this.popupBuyProcessing.initLoading(`Buying Safehouse.\nPlease, wait`);

        scene.game.events.emit(events.upgradeHouse, { quantity: this.quantity, token: this.purchaseToken });
      },
      onOpen: () => scene.game.events.emit(this.events.enableSalesTracking),
      onClose: () => scene.game.events.emit(this.events.disableSalesTracking),
    });
    scene.add.existing(this.popupConfirm);
    this.popupConfirm.updateTextLeft(`1${icon1Gap}unit`);

    this.buyBtn = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        if (isSimulator) {
          this.quantity = 1;
          this.popupBuyProcessing.initLoading(`Buying Safehouse.\nPlease, wait`);
          this.onCompleted = null;
          this.close();

          scene.game.events.emit(events.upgradeHouse, {
            quantity: this.quantity,
            token: this.purchaseToken,
          });
        } else {
          this.close();
          this.popupConfirm.open();
        }
      },
      'Buy',
      { fontSize: '82px', sound: 'buy' }
    );
    this.add(this.buyBtn);
    this.buyBtn.setDisabledState(!this.isSimulator);

    this.levelTitle = scene.add
      .text(this.popup.x + 80, this.popup.y - this.popup.height / 2 + 215, 'Safehouses:', {
        fontSize: fontSizes.large,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.levelTitle);

    this.safehouseLevelText = scene.add
      .text(this.popup.x + 150, this.popup.y - this.popup.height / 2 + 215, '', {
        fontSize: fontSizes.extraLarge,
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.add(this.safehouseLevelText);

    this.networthText = scene.add.text(this.popup.x + 380, networthY, '0', largeBlackExtraBold).setOrigin(1, 0);
    this.networthIncreaseText = scene.add
      .text(this.popup.x + this.popup.width * 0.4, networthY + 80, '+0', {
        fontSize: fontSizes.small,
        color: colors.green,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0);
    this.add(this.networthText);
    this.add(this.networthIncreaseText);

    this.minusBtn = new TextButton(
      scene,
      minusBtnX,
      counterY,
      'button-square',
      'button-square-pressed',
      () => {
        if (this.quantity > DEFAULT_QUANTITY) {
          this.quantity--;
          this.updateValues();
        }
      },
      '-',
      {
        disabledImage: 'button-square-disabled',
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.quantity > DEFAULT_QUANTITY) {
              this.quantity--;
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
        },
      }
    );
    this.minusBtn.setDisabledState(isSimulator);
    this.add(this.minusBtn);

    this.plusBtn = new TextButton(
      scene,
      minusBtnX + 350,
      counterY,
      'button-square',
      'button-square-pressed',
      () => {
        if (this.quantity < this.maxPerBatch) {
          this.quantity++;
          this.updateValues();
        }
      },
      '+',
      {
        disabledImage: 'button-square-disabled',
        fontSize: '82px',
        sound: 'button-1',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.quantity < this.maxPerBatch) {
              this.quantity++;
              this.updateValues();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
        },
      }
    );
    this.plusBtn.setDisabledState(isSimulator);
    this.add(this.plusBtn);

    this.quantityText = scene.add.text(minusBtnX + 170, counterY, this.quantity, {
      fontSize: '60px',
      fontFamily: fontFamilies.extraBold,
      color: '#7C2828',
    });
    this.quantityText.setOrigin(0.5, 0.5);
    this.add(this.quantityText);

    const priceTextX = this.popup.x + 160;
    this.priceText = scene.add.text(priceTextX, counterY, '0', largeBlackExtraBold).setOrigin(0, 0.5);
    this.add(this.priceText);
    this.gasPrice = scene.add
      .text(priceTextX, counterY, '+0 ETH (gas)', {
        fontSize: fontSizes.small,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, -1)
      .setVisible(!isSimulator);
    this.add(this.gasPrice);
    this.insufficientBalance = scene.add
      .text(priceTextX, counterY + 48, 'Insufficient xGREED', {
        fontSize: fontSizes.small,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0, -1)
      .setVisible(false);
    this.add(this.insufficientBalance);

    this.coin = scene.add
      .image(this.priceText.x + this.priceText.width + 10, counterY, 'icon-xgang-small')
      .setOrigin(0, 0.5)
      .setVisible(!isSimulator);
    this.add(this.coin);

    if (!isSimulator) {
      this.infoButton = new Button(
        scene,
        this.coin.x + this.coin.width + 30,
        counterY - 40,
        'button-info',
        'button-info-pressed',
        () => {
          if (isSimulator) return;
          this.close();
          this.scene.popupSafehousePrice?.open();
        },
        { sound: 'open' }
      );
      this.add(this.infoButton);
    }

    scene.game.events.on(events.completed, () => {
      this.quantity = DEFAULT_QUANTITY;
      this.updateValues();
    });

    scene.game.events.on(events.gameEnded, () => {
      this.buyBtn.setDisabledState(true);
    });
    scene.game.events.on('update-gas-upgrade-safehouse', ({ gas }) => {
      if (isNaN(gas)) return;

      this.gas = gas;
      this.updateValues();
    });

    scene.game.events.on(events.updateXTokenBalance, ({ balance }) => {
      this.xTokenBalance = balance;
      if (this.purchaseToken === 'xGREED') this.updateValues();
    });

    scene.game.events.on(
      events.updateBuildings,
      ({
        numberOfBuildings,
        basePrice,
        maxPerBatch,
        targetDailyPurchase,
        pricePower,
        targetPrice,
        totalSold,
        days,
        networthBase,
        networthPerDay,
        isTrackingSales,
        started,
        startingPrice,
      }) => {
        this.basePrice = basePrice;
        this.maxPerBatch = maxPerBatch;
        this.targetDailyPurchase = targetDailyPurchase;
        this.pricePower = pricePower;
        this.targetPrice = targetPrice;
        this.totalSold = totalSold;
        this.days = days;
        this.numberOfBuildings = numberOfBuildings;
        this.networthBase = networthBase;
        this.networthPerDay = networthPerDay;
        this.isTrackingSales = isTrackingSales;
        this.started = !!started;
        this.startingPrice = startingPrice;

        this.safehouseLevelText.text = `${numberOfBuildings?.toLocaleString()}`;
        this.levelTitle.x = this.popup.x + 80 - this.safehouseLevelText.width / 2 + 5;
        this.safehouseLevelText.x =
          this.levelTitle.x + this.levelTitle.width / 2 + this.safehouseLevelText.width / 2 + 10;
        this.updateValues();
      }
    );

    scene.game.events.on(events.updateXTokenBalance, (data) => {
      this.xTokenBalance = data.balance;
    });
    scene.game.events.on(events.updateNetworth, ({ networth }) => {
      this.networth = networth;
      this.networthText.text = `${networth.toLocaleString()}`;
      this.updateValues();
    });

    scene.game.events.emit(events.requestNetworth);

    scene.game.events.emit(events.requestBuildings);
    scene.game.events.emit(events.requestDecrementTime);
    scene.game.events.emit('request-gas-upgrade-safehouse');
    scene.game.events.emit('request-xtoken-balance');
  }

  onOpen() {
    this.scene.game.events.emit(this.events.enableSalesTracking);
    this.scene.game.events.emit(this.events.requestBuildings);
    if (this.isSimulator) return;
    this.scene.game.events.emit('request-house-price', { timeMode: this.scene.popupSafehousePrice.timeMode });
  }

  cleanup() {
    this.onCompleted?.();
    this.scene.game.events.emit(this.events.disableSalesTracking);
  }

  updateValues() {
    this.networthIncreaseText.text = `+${(this.networthBase * this.quantity).toLocaleString()} instant & +${(
      this.networthPerDay * this.quantity
    ).toLocaleString()} per day`;

    if (this.isSimulator) {
      this.priceText.text = 'FREE';
      this.priceText.x = width / 2 + 200;
      return;
    }

    this.xTokenMaxPurchase = estimateNumberOfBuildingCanBuy(
      this.xTokenBalance,
      this.totalSold,
      this.days,
      this.targetDailyPurchase,
      this.pricePower,
      this.targetPrice,
      this.basePrice,
      this.maxPerBatch
    );
    const estimatedPrice = this.started
      ? calculateNextBuildingBuyPriceBatch(
          this.totalSold,
          this.days,
          this.targetDailyPurchase,
          this.pricePower,
          this.targetPrice,
          this.basePrice,
          this.quantity
        ).total
      : this.startingPrice;

    this.quantityText.text = `${this.quantity}`;
    this.popupConfirm.updateTextLeft(`${this.quantity}${icon1Gap}unit${this.quantity > 1 ? 's' : ''}`);
    this.popupConfirm.updateTextRight(formatter.format(estimatedPrice.toPrecision(3)));
    this.priceText.text = `${customFormat(estimatedPrice, 1)}`;
    const formattedGas = customFormat(this.gas, 4) === '0' ? '<0.0001' : customFormat(this.gas, 4);
    this.gasPrice.text = `+${formattedGas} ETH (gas)`;
    this.gasPrice.setVisible(this.purchaseToken === 'GREED');
    this.coin.x = this.priceText.x + this.priceText.width + 10;
    if (this.infoButton) this.infoButton.x = this.coin.x + this.coin.width + 30;

    const maxPurchase = this.xTokenMaxPurchase;
    const insufficientBalance = this.quantity > maxPurchase;
    this.insufficientBalance.setVisible(insufficientBalance);
    this.buyBtn.setDisabledState(
      this.scene?.isGameEnded ||
        (!this.scene?.isUserActive && !this.isSimulator) ||
        insufficientBalance ||
        !this.isTrackingSales
    );
  }
}

export default PopupSafeHouseUpgrade;
