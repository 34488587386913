import Phaser from 'phaser';

import TutorialCharacter from './TutorialCharacter';
import InfoButtons from '../action-buttons/InfoButtons';
import configs from '../../configs/configs';

const { width, height } = configs;

const px = 40;
const buttonWidth = 230;
const verticalGap = 186 + 50;
const rewardBtnY = 550 + verticalGap * 2;
const spinBtnY = rewardBtnY + verticalGap * 2;

class Step11 extends Phaser.GameObjects.Container {
  clicked = false;

  constructor(scene, onNext) {
    super(scene, 0, 0);

    this.setVisible(false);

    this.character = new TutorialCharacter(scene, width / 2, height - 900, 'tutorial-11', () => {});
    this.add(this.character);

    this.infoButton = new InfoButtons(scene, 550, {
      noBackground: true,
      hideSettings: true,
      hideLeaderboard: true,
      hideAuction: true,
      referralDisabled: true,
      onClickReward: () => {
        if (this.clicked) return;
        this.clicked = true;

        this.character.y += 200;
        this.arrow.y = spinBtnY;
        this.arrow.x = buttonWidth + px + 330;
      },
      onClickSpin: () => {
        onNext();
      },
    });
    this.add(this.infoButton);

    this.arrow = scene.add.image(buttonWidth + px, rewardBtnY, 'tutorial-arrow-left').setOrigin(0, 0.5);
    this.add(this.arrow);
  }
}

export default Step11;
