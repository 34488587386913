import { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';

import useSystemStore from '../stores/system.store';

const useBlastGoldSnapshotCountdown = ({ open }) => {
  const activeSeason = useSystemStore((state) => state.activeSeason);
  const [snapshot, setSnapshot] = useState(null);

  const interval = useRef();
  useEffect(() => {
    if (!open || !activeSeason) {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    } else {
      const calculateEnd = () => {
        const { blastGoldSnapshotTime } = activeSeason;
        const now = moment(new Date());
        const snapshotTime = moment(blastGoldSnapshotTime.toDate());
        const diff = moment.duration(snapshotTime.diff(now));
        setSnapshot({
          days: Math.floor(diff.asDays()),
          hours: diff.hours(),
          minutes: diff.minutes(),
          seconds: diff.seconds(),
        });
      };
      interval.current = setInterval(calculateEnd, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [open, activeSeason?.blastGoldSnapshotTime]);

  const countdownString = useMemo(
    () =>
      snapshot ? `${snapshot.days}d ${snapshot.hours}h ${snapshot.minutes}m ${snapshot.seconds}s` : '--d --h --m --s',
    [snapshot]
  );

  const snapshotString = useMemo(
    () =>
      activeSeason?.blastGoldSnapshotTime
        ? moment(activeSeason.blastGoldSnapshotTime.toDate()).format(`DD/MM/YYYY [at] HH:mm:ss`)
        : '',
    [activeSeason?.blastGoldSnapshotTime]
  );

  return { countdownString, snapshotString };
};

export default useBlastGoldSnapshotCountdown;
