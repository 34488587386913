import Phaser from 'phaser';
// import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import AuctionItem from './AuctionItem';
import configs from '../../configs/configs';

const { width, height } = configs;

const containerWidth = 574;
const containerHeight = 851;
const gap = 40;

const startX = width / 2 - gap / 2 - containerWidth;
const startY = height * 0.22;

class AuctionItems extends Phaser.GameObjects.Container {
  auctionItems = [];
  items = [];

  constructor(scene, data) {
    super(scene);
    this.scene = scene;

    this.contentContainer = scene.add.container().setSize(width * 0.8, 0);
    this.loadingIcon = scene.add.image(width / 2, height / 2, 'icon-loading-small').setVisible(false);
    this.add(this.loadingIcon);
    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();

    if (data) {
      this.loadData(data);
    } else {
      this.showLoading();
      scene.game.events.emit('request-auction-items');
    }
  }

  refetch() {
    this.clearItems();
    this.scene.game.events.emit('request-auction-items');
  }

  showLoading() {
    this.loadingAnimation.resume();
    this.loadingIcon.setVisible(true);
  }

  hideLoading() {
    this.loadingIcon.setVisible(false);
    this.loadingAnimation.pause();
  }

  loadData(data) {
    this.hideLoading();
    this.auctionItems = data;
    this.renderList();
  }

  clearItems() {
    // clear old rendered items
    this.items.map((item) => {
      this.contentContainer.remove(item);
      item.destroyAll();
      // item.destroy();
    });
  }

  renderList() {
    // TODO: implement scrollable later, currently just render 4 items to prevent weird issues
    if (!this.auctionItems.length) return;

    this.items.map((item) => {
      item.destroyAll();
    });

    this.items = [];
    for (let i = 0; i < this.auctionItems.length; i++) {
      const auctionItem = this.auctionItems[i];
      const x = i % 2 === 0 ? startX : startX + containerWidth + gap;
      const y = startY + Math.floor(i / 2) * (containerHeight + gap);
      const item = new AuctionItem(this.scene, { x, y, item: auctionItem });
      this.items.push(item);
      this.add(item);
    }

    // if (!this.auctionItems.length) return;

    // this.clearItems();

    // this.items = [];
    // for (let i = 0; i < this.auctionItems.length; i++) {
    //   const auctionItem = this.auctionItems[i];
    //   const x = i % 2 === 0 ? startX : startX + containerWidth + gap;
    //   const y = startY + Math.floor(i / 2) * (containerHeight + gap) - height * 0.22;
    //   const item = new AuctionItem(this.scene, { x, y, item: auctionItem });
    //   this.items.push(item);
    // }
    // this.contentContainer.add(this.items);

    // const contentContainerHeight = Math.ceil(this.auctionItems.length / 2) * (containerHeight + gap);
    // this.contentContainer.setSize(0, contentContainerHeight);
    // if (this.table) {
    //   this.table.setMouseWheelScrollerEnable(false);
    //   this.table.setScrollerEnable(false);
    //   this.remove(this.table);
    //   this.table.destroy(true);
    //   this.table = null;
    // }

    // if (this.thumb) {
    //   this.remove(this.thumb);
    //   this.thumb.destroy(true);
    // }

    // const tableHeight = height * 0.67;
    // const visibleRatio = tableHeight / contentContainerHeight;
    // this.thumb = this.scene.rexUI.add
    //   .roundRectangle({
    //     height: visibleRatio < 1 ? tableHeight * visibleRatio : 0,
    //     radius: 13,
    //     color: 0xe3d6c7,
    //   })
    //   .setVisible(false);

    // this.table = new ScrollablePanel(this.scene, {
    //   x: width / 2 - 30,
    //   y: height * 0.22 + tableHeight / 2,
    //   width: width,
    //   height: tableHeight,
    //   scrollMode: 'y',
    //   background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
    //   panel: { child: this.contentContainer, mask: { padding: 1 } },
    //   slider: { thumb: this.thumb },
    //   mouseWheelScroller: { focus: true, speed: 0.3 },
    //   space: { left: 20, right: 20, top: 20, bottom: 20, panel: 20, header: 10, footer: 10 },
    // }).layout();

    // if (this.auctionItems.length <= 4 || !this.visible) {
    //   this.table.setMouseWheelScrollerEnable(false);
    //   this.table.setScrollerEnable(false);
    // } else {
    //   this.table.setMouseWheelScrollerEnable(true);
    //   this.table.setScrollerEnable(true);
    // }
    // this.add(this.table);

    // this.table.on('scroll', (e) => {
    //   // console.log('scroll', e.t); // e.t === scrolled percentage
    //   if (this.thumb.visible) return;
    //   this.thumb.setVisible(true);
    // });
  }
}

export default AuctionItems;
