import Popup from './Popup';
import TextButton from '../button/TextButton';
import TextInput from '../inputs/TextInput';

import configs from '../../configs/configs';
import { fontFamilies } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import { numberCharacterRegex, numberInputRegex } from '../../../../utils/strings';

const { width, height } = configs;

const buttonWidth = 506;
const btnGap = 50;
const counterBtnOffset = 180;
const INTERVAL = 100;
const fibonacis = [1, 2, 3, 5, 8, 13, 21];
const maxStepCount = 7;
const maxStepSizeIndex = fibonacis.length - 1;

class PopupAuctionBidding extends Popup {
  item = null;
  timeout = null;
  value = 0;
  quantity = 0;
  stepSizeIndex = 0;
  stepCount = 0;

  constructor(scene) {
    super(scene, 'popup-auction-bidding', { title: 'Auction Bidding Popup', x: width / 2, y: height / 2 - 300 });

    this.backBtn = new TextButton(
      scene,
      this.popup.x - buttonWidth / 2 - btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.onClickClose();
        this.close();
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.bidBtn = new TextButton(
      scene,
      this.popup.x + buttonWidth / 2 + btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-green',
      'button-green-pressed',
      () => {
        const valid = this.validate();
        if (!valid) return;
        this.close();
        scene.popupConfirmBidding?.show({ item: this.item, quantity: this.quantity, value: this.value });
      },
      'Bid',
      { sound: 'button-1', fontSize: '82px', disabledImage: 'button-disabled' }
    );
    this.bidBtn.setDisabledState(true);
    this.add(this.bidBtn);

    this.image = scene.add.sprite(this.popup.x, this.popup.y - 370, 'greed-prize-4').setOrigin(0.5, 0.5);
    this.image.scale = 1.25;
    this.add(this.image);

    const valueY = this.popup.y - 50;
    this.icon = scene.add.sprite(this.popup.x, valueY, 'icon-coin-small').setOrigin(0.5, 0.5);
    this.valueText = scene.add
      .text(this.popup.x, valueY, '0', { fontSize: '72px', fontFamily: fontFamilies.extraBold, color: '#7d2e00' })
      .setOrigin(0.5, 0.5);
    this.icon.x = this.popup.x - (this.icon.width + this.valueText.width + 20) / 2 + this.icon.width / 2;
    this.valueText.x = this.icon.x + this.icon.width / 2 + 20 + this.valueText.width / 2;
    this.add(this.icon);
    this.add(this.valueText);

    this.tokenInput = new TextInput(scene, this.popup.x, this.popup.y + 240, {
      inputImg: 'auction-text-box',
      icon: 'icon-xgang-small',
      iconX: this.popup.x + 190,
      iconHorizontalPadding: -50,
      placeholder: '0',
      unit: '',
      valueRegex: numberInputRegex,
      characterRegex: numberCharacterRegex,
      maxDisplayedCharacters: 9,
      onChange: (value) => {
        this.value = Number(value || '0');
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => this.validate(), 300);
      },
    });
    this.add(this.tokenInput);

    const quantityY = this.popup.y + 490;
    const minusBtn = new TextButton(
      scene,
      this.popup.x - counterBtnOffset,
      quantityY,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.quantity > 0) {
          this.quantity--;
          this.updateQuantity();
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => this.validate(), 300);
        }
      },
      '-',
      {
        fontSize: '82px',
        sound: 'button-1',
        disabledImage: 'button-square-tiny',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(() => {
            if (this.quantity > 0) {
              if (this.stepCount > maxStepCount) {
                this.stepCount = 0;
                this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
              } else {
                this.stepCount++;
              }

              const stepSize = fibonacis[this.stepSizeIndex];
              this.quantity = Math.max(0, this.quantity - stepSize);
              this.updateQuantity();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => this.validate(), 300);
        },
      }
    );
    const plusBtn = new TextButton(
      scene,
      this.popup.x + counterBtnOffset,
      quantityY,
      'button-square-tiny',
      'button-square-tiny-pressed',
      () => {
        if (this.quantity < this.item?.quantity) {
          this.quantity++;
          this.updateQuantity();
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => this.validate(), 300);
        }
      },
      '+',
      {
        fontSize: '82px',
        sound: 'button-1',
        disabledImage: 'button-square-tiny',
        onHold: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          const max = this.item?.quantity;
          this.interval = setInterval(() => {
            if (this.stepCount > maxStepCount) {
              this.stepCount = 0;
              this.stepSizeIndex = Math.min(this.stepSizeIndex + 1, maxStepSizeIndex);
            } else {
              this.stepCount++;
            }

            const stepSize = fibonacis[this.stepSizeIndex];
            if (this.quantity < max) {
              this.quantity = Math.min(max, this.quantity + stepSize);
              this.updateQuantity();
            }
          }, INTERVAL);
        },
        onRelease: () => {
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.stepSizeIndex = 0;
          this.stepCount = 0;
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = setTimeout(() => this.validate(), 300);
        },
      }
    );

    this.quantityText = scene.add
      .text(this.popup.x, quantityY, `${this.quantity}`, {
        fontSize: '72px',
        fontFamily: fontFamilies.extraBold,
        color: '#30030b',
      })
      .setOrigin(0.5, 0.5);

    this.add(minusBtn);
    this.add(plusBtn);
    this.add(this.quantityText);

    this.totalText = scene.add
      .text(this.popup.x, quantityY + 110, `Total: 0`, {
        fontSize: '48px',
        fontFamily: fontFamilies.bold,
        color: '#7D2E00',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.totalText);
  }

  bid(item) {
    this.item = item;
    const { name, value, valueImg, iconImg } = item;

    this.setTitle(name);
    this.image.setTexture(iconImg);
    this.icon.setTexture(valueImg);
    this.valueText.text = formatter.format(value);
    this.icon.x = this.popup.x - (this.icon.width + this.valueText.width + 20) / 2 + this.icon.width / 2;
    this.valueText.x = this.icon.x + this.icon.width / 2 + 20 + this.valueText.width / 2;

    this.open();
  }

  updateQuantity() {
    this.quantityText.text = `${this.quantity}`;
  }

  updateTotal() {
    let total = 0;
    if (!isNaN(this.value) && !isNaN(this.quantity)) {
      total = this.value * this.quantity;
    }
    this.totalText.text = `Total: ${formatter.format(total)}`;
  }

  validate() {
    let valid = true;

    if (!this.item) valid = false;
    if (!this.value || isNaN(this.value) || this.value < 0) valid = false;
    if (!this.quantity || isNaN(this.quantity) || this.quantity <= 0 || this.quantity > this.item.quantity)
      valid = false;

    this.bidBtn.setDisabledState(!valid);
    this.updateTotal();

    return valid;
  }

  reset() {
    this.quantity = 0;
    this.value = 0;
    this.updateQuantity();
    this.tokenInput.updateValue('0', true, true);
  }

  onClickClose() {
    this.reset();
  }
}

export default PopupAuctionBidding;
