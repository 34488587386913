import Phaser from 'phaser';

import Button from '../button/Button';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { fontFamilies } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import { getTwitterIntentUrl } from '../../../../utils/strings';

const { width, height } = configs;

class PopupPlaytestAirdrop extends Phaser.GameObjects.Container {
  value = 0;
  template = null;
  loading = false;

  constructor(scene) {
    super(scene, 0, 0);
    this.scene = scene;
    this.setVisible(false);

    this.background = scene.add.rectangle(0, 0, configs.width, configs.height, 0x260343, 0.8).setOrigin(0, 0);
    this.add(this.background);

    this.playtestAirdropImage = scene.add.image(width / 2, height / 2 - 330, 'playtest-airdrop').setOrigin(0.5, 0.5);
    this.add(this.playtestAirdropImage);

    this.closeBtn = new Button(
      scene,
      width - 100,
      this.playtestAirdropImage.y - this.playtestAirdropImage.height / 2 - 100,
      'button-close',
      'button-close-pressed',
      () => {
        this.close();
      },
      { sound: 'close' }
    );
    this.add(this.closeBtn);

    const amountY = this.playtestAirdropImage.y + this.playtestAirdropImage.height / 2 + 90;
    this.amountText1 = scene.add
      .text(width / 2, amountY, '0', {
        fontSize: '116px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFDD1D',
      })
      .setOrigin(0.5, 0.5);
    this.amountText1.setStroke('#591C9A', 10);
    this.add(this.amountText1);

    this.amountText2 = scene.add
      .text(width / 2, amountY, '$GREED', {
        fontSize: '116px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFFFFF',
      })
      .setOrigin(0.5, 0.5);
    this.amountText2.setStroke('#591C9A', 10);
    this.add(this.amountText2);

    const shareTextY = this.amountText2.y + this.amountText2.height / 2 + 70;
    this.shareText = scene.add
      .text(width / 2, shareTextY, 'Share to claim', {
        fontSize: '64px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFFFFF',
      })
      .setOrigin(0.5, 0.5);
    this.shareText.setStroke('#591C9A', 10);
    this.add(this.shareText);

    const shareBtnY = this.shareText.y + this.shareText.height / 2 + 200;
    this.shareBtn = new Button(
      scene,
      width / 2,
      shareBtnY,
      'button-share-on-x',
      'button-share-on-x-pressed',
      () => {
        this.share();
      },
      { sound: 'button-1' }
    );
    this.add(this.shareBtn);

    this.activeClaimBtn = new TextButton(
      scene,
      width / 2,
      shareBtnY,
      'button-playtest-claim',
      'button-playtest-claim-pressed',
      () => {
        this.claim();
      },
      'Claim',
      { fontSize: '82px', sound: 'button-1' }
    );
    this.activeClaimBtn.setVisible(false);
    this.add(this.activeClaimBtn);

    const claimBtnY = this.shareBtn.y + this.shareBtn.height / 2 + 130;
    this.claimBtn = new Button(
      scene,
      width / 2,
      claimBtnY,
      'button-playtest-claim-disabled',
      'button-playtest-claim-disabled',
      () => {
        console.log('claim disabled');
      },
      { sound: 'button-1' }
    );
    this.claimBtn.setDisabledState(true);
    this.add(this.claimBtn);

    scene.game.events.on('update-twitter-share-playtest-airdrop-template', ({ template }) => {
      this.template = template;
    });

    scene.game.events.on('share-playtest-done', () => {
      this.loading = false;
    });

    scene.game.events.on('claim-playtest-done', () => {
      this.loading = false;
    });

    scene.game.events.emit('request-twitter-share-playtest-airdrop-template');
    scene.game.events.emit('request-airdrop-status');
  }

  open() {
    this.setVisible(true);
  }

  close = () => {
    this.setVisible(false);
    this.loading = false;
  };

  show({ value, shared, claimed, claiming }) {
    if (claimed || claiming) {
      this.close();
      return;
    }

    this.loading = false;
    this.value = value;
    this.amountText1.text = formatter.format(value);
    this.amountText1.x = width / 2 - this.amountText2.width / 2 + 10;
    this.amountText2.x = this.amountText1.x + this.amountText1.width / 2 + this.amountText2.width / 2 + 20;
    if (shared) {
      this.afterShare();
    }
    this.setVisible(true);
    window.poof?.();
  }

  share() {
    if (!this.template || this.loading) return;
    this.loading = true;
    const text = this.template
      .replace('{value}', formatter.format(this.value))
      .replace('{siteUrl}', window.location.origin);
    const intentUrl = getTwitterIntentUrl({ text });
    window.open(intentUrl);
    this.scene.game.events.emit('share-playtest');
  }

  afterShare() {
    this.shareText.setVisible(false);
    this.shareBtn.setVisible(false);
    this.claimBtn.setVisible(false);
    this.activeClaimBtn.setVisible(true);
  }

  claim() {
    if (this.loading) return;
    this.loading = true;
    this.activeClaimBtn.updateText('Claiming...');

    this.scene.game.events.emit('claim-playtest');
  }
}

export default PopupPlaytestAirdrop;
