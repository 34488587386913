import Phaser from 'phaser';

import ActiveClaimButton from './ActiveClaimButton';
import DisabledClaimButton from './DiabledClaimButton';
import InActiveClaimButton from './InActiveClaimButton';
import { fontFamilies, fontSizes } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import configs from '../../configs/configs';

const { width } = configs;

class ClaimButton extends Phaser.GameObjects.Container {
  claimableAmount = 0;
  numOfCoins = 1;
  claimSound = null;

  constructor(scene, x, y) {
    super(scene, 0, 0);

    this.coinbagDropoffSound = scene.sound.add('coinbag-dropoff', { loop: false });
    this.addedAmountContainer = scene.add.container(x + 400, 1900, '');
    this.addedAmount = scene.add
      .text(0, 0, '', {
        fontSize: fontSizes.large,
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.addedAmount.setStroke('#000', 10);
    this.glowingCoin = scene.add.image(0, 0, 'icon-xcoin-glowing').setVisible(false);
    this.addedAmountContainer.add(this.addedAmount);
    this.addedAmountContainer.add(this.glowingCoin);

    this.activeClaimButton = new ActiveClaimButton(scene, x, y, { parent: this });
    this.inactiveClaimButton = new InActiveClaimButton(scene, x, y);
    this.disabledClaimButton = new DisabledClaimButton(scene, x, y);

    this.activeClaimButton.setVisible(false);
    this.disabledClaimButton.setVisible(false);
    this.inactiveClaimButton.setVisible(false);

    this.add(this.activeClaimButton);
    this.add(this.disabledClaimButton);
    this.add(this.inactiveClaimButton);

    this.claim1Sound = scene.sound.add('claim-1', { loop: false });
    this.claim2Sound = scene.sound.add('claim-2', { loop: false });
    this.claim3Sound = scene.sound.add('claim-3', { loop: false });
    this.claim4Sound = scene.sound.add('claim-4', { loop: false });
    this.claimSound = this.claim1Sound;

    scene.game.events.on('game-ended', () => {
      this.inactiveClaimButton.setVisible(true);
      this.activeClaimButton.setVisible(false);
      this.disabledClaimButton.setVisible(false);
    });

    scene.game.events.on('update-claimable-status', ({ claimable, active }) => {
      if (!active || scene.isGameEnded) {
        this.inactiveClaimButton.setVisible(true);
      } else if (claimable) {
        this.activeClaimButton.setVisible(true);
        this.disabledClaimButton.setVisible(false);
        this.inactiveClaimButton.setVisible(false);
      } else {
        this.activeClaimButton.setVisible(false);
        this.disabledClaimButton.setVisible(true);
        this.inactiveClaimButton.setVisible(false);
      }
    });

    scene.game.events.on('claimable-reward-added', () => {
      this.coinbagDropoffSound.play();
      this.glowingCoin.x = this.addedAmount.width / 2 + 80;
      this.glowingCoin.setVisible(true);

      this.scene.tweens.add({
        targets: this.addedAmountContainer,
        scale: [1, 0.5, 0],
        duration: 1200,
        ease: 'Cubic.out',
        hideOnComplete: true,
      });
    });

    scene.game.events.on('update-claimable-reward', ({ reward }) => {
      const difference = this.claimableAmount ? reward - this.claimableAmount : 0;
      this.addedAmount.text = `+${formatter.format(difference)}`;
      this.claimableAmount = reward;
      if (reward >= 100000) {
        this.numOfCoins = 50;
        this.claimSound = this.claim4Sound;
      } else if (reward >= 10000) {
        this.numOfCoins = 20;
        this.claimSound = this.claim3Sound;
      } else if (reward >= 1000) {
        this.numOfCoins = 5;
        this.claimSound = this.claim2Sound;
      } else {
        this.numOfCoins = 1;
        this.claimSound = this.claim1Sound;
      }
    });

    scene.game.events.emit('request-claimable-reward');
    scene.game.events.emit('request-claimable-status');
  }

  startCoinAnimation() {
    const duration = 2400;
    let isClaimSoundPlayed = false;

    for (let i = 0; i < this.numOfCoins; i++) {
      setTimeout(() => {
        if (!isClaimSoundPlayed) {
          this.claimSound.play();
          isClaimSoundPlayed = true;
        }
        const emitter = this.scene.add.particles(0, 0, 'flare', {
          blendMode: 'ADD',
          lifespan: 500,
          scale: { start: 1, end: 0 },
        });
        const coinIcon = this.scene.add.image(width / 2, this.y, 'icon-xcoin-glowing');

        const path = generateRandomPath();
        const curve = new Phaser.Curves.Spline(path.map((p) => new Phaser.Math.Vector2(p.x, p.y)));
        const pathXs = path.map(({ x }) => x);
        const pathYs = path.map(({ y }) => y);

        this.scene.tweens.add({
          targets: coinIcon,
          x: pathXs,
          y: pathYs,
          duration,
          ease: 'Cubic.out',
          interpolation: 'bezier', // turn the points into curve
          onUpdate: function (tween) {
            const t = tween.progress;
            const position = curve.getPoint(t);
            coinIcon.setPosition(position.x, position.y);
          },
          hideOnComplete: true,
        });

        emitter.startFollow(coinIcon);

        setTimeout(() => {
          coinIcon.destroy();
          emitter.destroy();
        }, duration);
      }, Math.random() * 1000);
    }
  }
}

const numPoints = 7;
const yEnd = 250;
const xStart = width / 2 + 80;
const xEnd = width / 2 - 580;
const xVariance = 400;
const yVariance = 50;

export const generateRandomPath = ({ yStart = 2600 } = {}) => {
  let path = [];

  // Start point
  path.push({ x: xStart, y: yStart });

  // Generate intermediate points
  for (let i = 1; i < numPoints - 1; i++) {
    let x = xStart + (xEnd - xStart) * (i / (numPoints - 1));
    let y = yStart + (yEnd - yStart) * (i / (numPoints - 1));

    // Add random variance
    x += (Math.random() * 2 - 1) * xVariance;
    y += (Math.random() * 2 - 1) * yVariance;

    path.push({ x, y });
  }

  // End point
  path.push({ x: xEnd, y: yEnd });

  return path;
};

export default ClaimButton;
