import Phaser from 'phaser';

const verticalGap = 280;

class PopupWar extends Phaser.GameObjects.Container {
  historyDisabled = false;
  warDisabled = false;
  twitterVerified;
  numberOfMachines = 0;
  gangwarMinNumberOfMachines = 0;
  onClick = () => {};

  constructor(scene, x, y, { isSimulator } = {}) {
    super(scene, 0, 0);
    this.twitterVerified = isSimulator;

    const events = {
      requestTwitterVerified: isSimulator ? 'simulator-request-twitter-verified' : 'request-twitter-verified',
      updateTwitterVerified: isSimulator ? 'simulator-update-twitter-verified' : 'update-twitter-verified',
      requestMachineNumbers: isSimulator ? 'simulator-require-machine-numbers' : 'request-machine-numbers',
      updateMachineNumbers: isSimulator ? 'simulator-update-machine-numbers' : 'update-machine-numbers',
    };

    this.popup = scene.add.image(x, y, 'menu-long').setOrigin(0, 0);
    this.upgradesBtn = scene.add.image(x + this.popup.width / 2, y + 200, 'icon-upgrades').setOrigin(0.5, 0.5);
    this.historyBtn = scene.add
      .image(x + this.popup.width / 2, this.upgradesBtn.y + verticalGap, 'icon-history')
      .setOrigin(0.5, 0.5);
    this.warBtn = scene.add
      .image(x + this.popup.width / 2, this.historyBtn.y + verticalGap, 'icon-war')
      .setOrigin(0.5, 0.5);

    this.btnSound = scene.sound.add('open', { loop: false });

    this.upgradesBtn.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
      if (this.upgradesDisabled) return;
      if (isSimulator) {
        this.onClick?.();
        return;
      }

      this.btnSound.play();
      scene.popupWarUpgrades.open();
      this.onClick?.();
    });
    this.historyBtn.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
      if (this.historyDisabled) return;
      this.btnSound.play();
      scene.popupWarHistory.open();
      this.onClick?.();
    });
    this.warBtn.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
      if (this.warDisabled) return;
      this.btnSound.play();
      if (isSimulator) {
        this.onClick?.();
        return;
      }

      if (!this.twitterVerified) {
        scene.popupLinkX?.open();
        return;
      }

      if (this.numberOfMachines < this.gangwarMinNumberOfMachines) {
        scene?.popupGangwarRequirement?.show({
          numberOfMachines: this.numberOfMachines,
          gangwarMinNumberOfMachines: this.gangwarMinNumberOfMachines,
        });
        return;
      }

      scene.popupWarMachines?.open();
    });

    this.add(this.popup);
    this.add(this.upgradesBtn);
    this.add(this.historyBtn);
    this.add(this.warBtn);
    this.setVisible(false);

    scene.game.events.on(events.updateTwitterVerified, ({ twitterVerified }) => {
      this.twitterVerified = twitterVerified;
    });

    scene.game.events.on(events.updateMachineNumbers, ({ numberOfMachines, gangwarMinNumberOfMachines }) => {
      this.numberOfMachines = numberOfMachines || 0;
      this.gangwarMinNumberOfMachines = gangwarMinNumberOfMachines || 0;
    });

    scene.game.events.emit(events.requestTwitterVerified);
    scene.game.events.emit(events.requestMachineNumbers);
  }

  updateDisabled({ upgradesDisabled, historyDisabled, warDisabled }) {
    this.upgradesDisabled = upgradesDisabled;
    this.historyDisabled = historyDisabled;
    this.warDisabled = warDisabled;

    if (upgradesDisabled) {
      this.upgradesBtn.setAlpha(0.3);
    } else {
      this.upgradesBtn.setAlpha(1);
    }

    if (historyDisabled) {
      this.historyBtn.setAlpha(0.3);
    } else {
      this.historyBtn.setAlpha(1);
    }

    if (warDisabled) {
      this.warBtn.setAlpha(0.3);
    } else {
      this.warBtn.setAlpha(1);
    }
  }

  updateCallback(callback) {
    this.onClick = callback;
  }
}

export default PopupWar;
