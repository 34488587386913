import Phaser from 'phaser';

import Button from '../button/Button';
import { fontFamilies } from '../../../../utils/styles';
import configs from '../../configs/configs';

const { width, height } = configs;

class BiddingHistoryButton extends Phaser.GameObjects.Container {
  constructor(scene) {
    super(scene, 0, 0);

    const y = height * 0.95;

    this.icon = scene.add.image(width / 2 - 250, y, 'union-brown').setOrigin(1, 0.5);
    this.add(this.icon);
    this.text = scene.add
      .text(this.icon.x + 35, y, 'My Bidding History', {
        fontSize: '60px',
        fontFamily: fontFamilies.bold,
        color: '#7d2e00',
      })
      .setOrigin(0, 0.5);
    this.text.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
      scene.popupBiddingHistory?.open();
    });
    this.add(this.text);
    const underline = scene.add
      .rectangle(this.text.x, this.text.y + this.text.height / 2, this.text.width, 2, 0x7d2e00, 1)
      .setOrigin(0, 0);
    this.add(underline);
  }
}

export default BiddingHistoryButton;
