import Phaser from 'phaser';

import AuctionHeader from '../components/action-buttons/AuctionHeader';
import Header from '../components/action-buttons/Header';
import BiddingHistoryButton from '../components/action-buttons/BiddingHistoryButton';
import AuctionCountdown from '../components/common/AuctionCountdown';
import AuctionItems from '../components/common/AuctionItems';
import PopupAuctionBidding from '../components/popup/PopupAuctionBidding';
import PopupConfirmBidding from '../components/popup/PopupConfirmBidding';
import PopupBiddingDone from '../components/popup/PopupBiddingDone';
import PopupBiddingHistory from '../components/popup/PopupBiddingHistory';
import PopupAuctionItemHistory from '../components/popup/PopupAuctionItemHistory';
import PopupAuctionItemHistoryDetail from '../components/popup/PopupAuctionItemHistoryDetail';
import configs from '../configs/configs';

const { width, height } = configs;

class AuctionScene extends Phaser.Scene {
  auctionItems = null;

  constructor() {
    super('AuctionScene');
  }

  preload() {
    this.setupListeners();

    this.background = this.add.image(width / 2, height / 2, 'auction-bg').setOrigin(0.5, 0.5);
    this.background.setDisplaySize(width, height);
    this.add.existing(this.background);

    this.auctionHeader = new AuctionHeader(this);
    this.add.existing(this.auctionHeader);

    this.header = new Header(this, 330, {
      xTokenClick: () => {
        this.scene.pause('AuctionScene');
        this.scene.resume('MainScene');
        this.scene.bringToTop('MainScene');
        const mainScene = this.scene.get('MainScene');
        mainScene.popupBuyGoon?.open();
      },
      tokenClick: () => {
        this.scene.pause('AuctionScene');
        this.scene.resume('MainScene');
        this.scene.bringToTop('MainScene');
        const mainScene = this.scene.get('MainScene');
        mainScene.popupSwap?.open();
      },
      ethClick: () => {
        this.scene.pause('AuctionScene');
        this.scene.resume('MainScene');
        this.scene.bringToTop('MainScene');
        const mainScene = this.scene.get('MainScene');
        mainScene.popupDeposit?.open();
      },
    });
    this.add.existing(this.header);

    this.auctionCountdown = new AuctionCountdown(this);
    this.add.existing(this.auctionCountdown);

    this.viewHistoryButton = new BiddingHistoryButton(this);
    this.add.existing(this.viewHistoryButton);

    this.popupAuctionBidding = new PopupAuctionBidding(this);
    this.add.existing(this.popupAuctionBidding);

    this.popupConfirmBidding = new PopupConfirmBidding(this);
    this.add.existing(this.popupConfirmBidding);

    this.popupBiddingDone = new PopupBiddingDone(this);
    this.add.existing(this.popupBiddingDone);

    const pluginLoader = this.load.scenePlugin({
      key: 'rexuiplugin',
      url: '/libs/rexui.min.js',
      sceneKey: 'rexUI',
    });
    pluginLoader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.popupBiddingHistory = new PopupBiddingHistory(this);
      this.add.existing(this.popupBiddingHistory);

      this.popupAuctionItemHistory = new PopupAuctionItemHistory(this);
      this.add.existing(this.popupAuctionItemHistory);

      this.popupAuctionItemHistoryDetail = new PopupAuctionItemHistoryDetail(this);
      this.add.existing(this.popupAuctionItemHistoryDetail);

      this.items = new AuctionItems(this);
      this.add.existing(this.items);
    });
  }

  create() {}

  update() {}

  shutdown() {
    this.game.events.removeListeners([
      'auction-changed',
      'request-aution-items',
      'load-auction-items',
      'bid',
      'create-bidding-success',
      'create-bidding-fail',
      'request-bidding-history',
      'load-bidding-history',
      'request-auction-item-bidding-history',
      'load-auction-item-bidding-history',
      'request-auction-end-time',
      'load-auction-end-time',
    ]);
  }

  // custom
  setupListeners() {
    this.game.events.on('auction-changed', () => this.refreshItems(true));
    this.game.events.on('request-auction-items', () => this.game.events.emit('get-auction-items'));
    this.game.events.on('load-auction-items', (data) => {
      this.auctionItems = data;
      this.items?.loadData(data);
    });
    this.game.events.on('bid', (data) => this.game.events.emit('create-bidding', data));
    this.game.events.on('create-bidding-success', () => this.game.events.emit('bid-success'));
    this.game.events.on('create-bidding-fail', (err) => this.game.events.emit('bid-error', err));
    this.game.events.on('request-bidding-history', () => this.game.events.emit('get-bidding-history'));
    this.game.events.on('load-bidding-history', (data) => this.popupBiddingHistory?.loadData(data));
    this.game.events.on('request-auction-item-bidding-history', ({ subId }) =>
      this.game.events.emit('get-auction-item-bidding-history', { subId })
    );
    this.game.events.on('load-auction-item-bidding-history', (data) => this.popupAuctionItemHistory?.loadData(data));
    this.game.events.on('request-auction-item-bidding-history-detail', ({ subId, recordId }) =>
      this.game.events.emit('get-auction-item-bidding-history-detail', { subId, recordId })
    );
    this.game.events.on('load-auction-item-bidding-history-detail', (data) =>
      this.popupAuctionItemHistoryDetail?.loadData(data)
    );
    this.game.events.on('request-auction-end-time', () => this.game.events.emit('get-auction-end-time'));
    this.game.events.on('load-auction-end-time', (data) => this.auctionCountdown?.loadData(data));
  }

  refreshItems(reload = false) {
    const removedItems = this.items;
    this.items = new AuctionItems(this, reload ? null : this.auctionItems);
    this.add.existing(this.items);
    removedItems.destroy();

    if (reload) {
      this.auctionCountdown?.getAuctionEndTime();
    }
  }
}

export default AuctionScene;
