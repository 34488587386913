import { useState, useEffect, useMemo } from 'react';
import { faker } from '@faker-js/faker';
import * as Sentry from '@sentry/react';

import useSystemStore from '../stores/system.store';
import useUserStore from '../stores/user.store';
import useSeasonCountdown from './useSeasonCountdown';
import { calculateHouseLevel } from '../utils/formulas';
import { completeTutorial } from '../services/user.service';
import { getNextWarSnapshotUnixTime } from '../services/gamePlay.service';
import purchaseConfig from '../assets/jsons/purchaseConfig.json';

const MILISECONDS_IN_A_DAY = 86400 * 1000;
const MILLISECONDS_PER_MINUTE = 60 * 1000;

const useSimulatorGameListener = () => {
  const user = useUserStore((state) => state.profile);
  const activeSeason = useSystemStore((state) => state.activeSeason);
  const market = useSystemStore((state) => state.market);
  const templates = useSystemStore((state) => state.templates);
  const [gameRef, setGameRef] = useState(null);
  const [balances, setBalances] = useState({ xTokenBalance: 0, tokenBalance: 0 });
  const [assets, setAssets] = useState({
    numberOfMachines: 0,
    numberOfAvailableMachines: 0,
    numberOfWorkers: 0,
    numberOfBuildings: 0,
    networth: 0,
    warDeployment: {
      numberOfMachinesToEarn: 0,
      numberOfMachinesToAttack: 0,
      numberOfMachinesToDefend: 0,
      attackUsers: [],
    },
  });
  const [isLeaderboardModalOpen, setLeaderboardModalOpen] = useState(false);
  const { isEnded, countdownString, started, startTimeString } = useSeasonCountdown({ open: isLeaderboardModalOpen });

  const leaderboardData = useMemo(() => {
    if (!user || !activeSeason?.prizePoolConfig) return [];

    const allUsers = [
      ...mockUsers,
      {
        avatarURL: user.avatarURL,
        id: faker.string.uuid(),
        networth: assets.networth,
        totalRewardInEth: 0,
        raidPoint: 0,
        userId: user.id,
        username: user.username,
        isUser: true,
      },
    ].sort((user1, user2) => user2.networth - user1.networth);

    const totalNetworth = allUsers.reduce((sum, doc) => sum + doc.networth, 0);
    const totalRaidPoints = allUsers.reduce((sum, doc) => sum + doc.raidPoint, 0);

    return allUsers.map((user, index) => {
      return {
        ...user,
        rank: index + 1,
        totalRewardInEth: (user.networth / totalNetworth) * activeSeason?.prizePoolEth,
      };
    });
  }, [user, activeSeason?.prizePoolEth, assets?.networth, activeSeason?.prizePoolConfig]);

  const setupSimulatorGameListener = (game) => {
    setGameRef(game);

    const now = Date.now();
    const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];

    game.events.on('simulator-end', () => {
      console.log('simulator-end');
      completeTutorial()
        .then(() => console.log('completed tutorial'))
        .catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
      setGameRef(null);
    });

    game.events.on('simulator-request-prize-pool-info', () => {
      game.events.emit('simulator-update-prize-pool-info', {
        prizePoolConfig: activeSeason?.prizePoolConfig,
        xUPool: activeSeason?.xUReward,
        blastGoldReward: activeSeason?.blastGoldReward,
      });
    });

    game.events.on('simulator-request-balances', () => {
      game.events.emit('simulator-update-balances', { ...balances, ETHBalance: user?.ETHBalance || 0 });
    });

    game.events.on('simulator-request-xtoken-balance', () => {
      game.events.emit('simulator-update-xtoken-balance', { balance: balances.xTokenBalance });
    });

    game.events.on('simulator-request-machines', () => {
      const now = Date.now();
      game.events.emit('simulator-update-machines', {
        numberOfMachines: assets.numberOfMachines,
        balance: user?.ETHBalance || 0,
        level: 0,
        dailyReward: activeSeason?.machine?.dailyReward,
        earningRateIncrementPerLevel: activeSeason?.machine?.earningRateIncrementPerLevel,
        basePrice: activeSeason?.machine.basePrice || 0,
        basePriceWhitelist: activeSeason?.machine.basePrice || 0,
        maxPerBatch: activeSeason?.machine.maxPerBatch,
        dailyReward: activeSeason?.machine.dailyReward || 0,
        networthBase: activeSeason?.machine.networthBase,
        networthPerDay: activeSeason?.machine.networthPerDay,
        tokenPrice: market?.tokenPrice || 0,
        targetDailyPurchase: activeSeason?.machine.targetDailyPurchase,
        pricePower: activeSeason?.machine.pricePower,
        targetPrice: activeSeason?.machine.targetPrice,
        levelPower: activeSeason?.machine.levelPower,
        levelMultiple: activeSeason?.machine.levelMultiple,
        dailyWarCount: 24 / activeSeason?.warConfig?.warIntervalDurationInHours || 1,
        totalSold: activeSeason.machineSold + fakePurchases.numberOfMachineFakePurchases,
        days: gameDays,
        started: now > activeSeason.startTime.toDate().getTime(),
        startingPrice: activeSeason?.machine?.startingPrice || 0,
      });
    });

    game.events.on('simulator-request-workers', () => {
      const now = Date.now();

      game.events.emit('simulator-update-workers', {
        numberOfWorkers: assets.numberOfWorkers,
        balance: balances.tokenBalance,
        sold: assets.numberOfWorkers,
        basePrice: activeSeason?.worker.basePrice,
        maxPerBatch: activeSeason?.worker.maxPerBatch,
        targetDailyPurchase: activeSeason?.worker.targetDailyPurchase,
        targetPrice: activeSeason?.worker.targetPrice,
        dailyReward: activeSeason?.worker.dailyReward,
        networthBase: activeSeason?.worker.networthBase,
        networthPerDay: activeSeason?.worker.networthPerDay,
        totalSold: activeSeason.workerSold + fakePurchases.numberOfWorkerFakePurchases,
        days: gameDays,
        started: now > activeSeason.startTime.toDate().getTime(),
        startingPrice: activeSeason?.worker?.startingPrice || 0,
      });
    });

    game.events.on('simulator-request-buildings', () => {
      const now = Date.now();

      game.events.emit('simulator-update-buildings', {
        numberOfBuildings: assets.numberOfBuildings,
        basePrice: activeSeason?.building.basePrice,
        maxPerBatch: activeSeason?.building.maxPerBatch,
        targetDailyPurchase: activeSeason?.building.targetDailyPurchase,
        pricePower: activeSeason?.building?.pricePower,
        targetPrice: activeSeason?.building.targetPrice,
        networthBase: activeSeason?.building.networthBase,
        networthPerDay: activeSeason?.building.networthPerDay,
        totalSold: activeSeason.buildingSold + fakePurchases.numberOfBuildingFakePurchases,
        days: gameDays,
        started: now > activeSeason.startTime.toDate().getTime(),
        startingPrice: activeSeason?.building?.startingPrice || 0,
      });
    });

    // reset assets & giveaway 1 goon
    game.events.on('simulator-reset-assets', () => {
      setAssets({
        numberOfMachines: 0,
        numberOfWorkers: 0,
        numberOfBuildings: 0,
        networth: 0,
        warDeployment: {
          numberOfMachinesToEarn: 0,
          numberOfMachinesToAttack: 0,
          numberOfMachinesToDefend: 0,
          attackUsers: [],
        },
      });
    });

    game.events.on('simulator-claim-completed', async ({ amount }) => {
      setBalances((prevState) => ({
        ...prevState,
        xTokenBalance: prevState.xTokenBalance + amount,
      }));
    });

    game.events.on('simulator-buy-gangster', async ({ quantity }) => {
      await delay(1000);
      setAssets((prevAssets) => ({
        ...prevAssets,
        numberOfMachines: prevAssets.numberOfMachines + quantity,
        numberOfAvailableMachines: prevAssets.numberOfAvailableMachines + quantity,
        networth: prevAssets.networth + quantity * activeSeason.machine.networthBase,
      }));
      game.events.emit('simulator-buy-gangster-completed', { txnHash: '', amount: quantity });
    });

    game.events.on('simulator-buy-goon', async ({ quantity, delayDuration = 1000, hideSuccessPopup = false }) => {
      await delay(delayDuration);
      setAssets((prevAssets) => ({
        ...prevAssets,
        numberOfWorkers: prevAssets.numberOfWorkers + quantity,
        networth: prevAssets.networth + quantity * activeSeason.worker.networthBase,
      }));
      if (!hideSuccessPopup) game.events.emit('simulator-buy-goon-completed', { txnHash: '', amount: quantity });
    });

    game.events.on('simulator-upgrade-safehouse', async ({ quantity }) => {
      await delay(1000);
      setAssets((prevAssets) => ({
        ...prevAssets,
        numberOfBuildings: prevAssets.numberOfBuildings + quantity,
        networth: prevAssets.networth + quantity * activeSeason.building.networthBase,
      }));
      game.events.emit('simulator-upgrade-safehouse-completed', { txnHash: '', amount: quantity });
    });

    game.events.on('simulator-request-workers-machines', () => {
      game.events.emit('simulator-update-workers-machines', {
        numberOfWorkers: assets.numberOfWorkers,
        numberOfMachines: assets.numberOfMachines,
      });
    });

    game.events.on('simulator-request-networth', () => {
      game.events.emit('simulator-update-networth', {
        networth: assets.networth,
        level: calculateHouseLevel(activeSeason?.houseLevels || [], assets.networth),
      });
    });

    game.events.on('simulator-open-leaderboard-modal', () => {
      setLeaderboardModalOpen(true);
      const { name, prizePoolEth, prizePoolToken, blastGoldReward, xUReward } = activeSeason || {};
      game.events.emit('simulator-update-season', {
        name,
        prizePoolEth,
        prizePoolToken,
        blastGoldReward,
        xUPool: xUReward,
        isEnded,
      });
    });

    game.events.on('simulator-close-leaderboard-modal', () => {
      setLeaderboardModalOpen(false);
    });

    game.events.on('simulator-request-rank', () => {
      const index = leaderboardData.findIndex((item) => item.isUser);
      if (index > -1) {
        game.events.emit('simulator-update-rank', { rank: index + 1 });
      }
    });

    game.events.on('simulator-request-game-play', () => {
      game.events.emit('simulator-update-game-play', {
        numberOfMachines: assets.numberOfMachines,
        numberOfAvailableMachines: assets.numberOfAvailableMachines,
        numberOfWorkers: assets.numberOfWorkers,
        numberOfBuildings: assets.numberOfBuildings,
        ...assets.warDeployment,
        ...activeSeason?.warConfig,
      });
    });

    game.events.on('simulator-request-u-point-reward', () => {
      game.events.emit('simulator-update-u-point-reward', { uPointReward: 0 });
    });

    game.events.on('simulator-request-twitter-share-template', () => {
      game.events.emit('simulator-update-twitter-share-template', {
        template: templates.twitterShareReferralCode,
        referralCode: user?.referralCode || '',
      });
    });

    game.events.on('simulator-request-next-war-time', () => {
      getNextWarSnapshotUnixTime()
        .then((res) => {
          game.events.emit('simulator-update-next-war-time', { time: res.data.time });
        })
        .catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
    });

    game.events.on('simulator-request-war-die-chance', () => {
      game.events.emit('simulator-update-war-die-chance', { dieChance: activeSeason?.warConfig?.dieChance });
    });

    game.events.on('simulator-update-war-machines', () => {
      game.events.emit('simulator-update-war-machines-completed', {});
    });

    game.events.on('simulator-refresh-eth-balance', () => {
      game.events.emit('simulator-refresh-eth-balance-completed');
    });

    game.events.on('simulator-request-eth-balance', async () => {
      game.events.emit('simulator-update-eth-balance', { address: user.address, ETHBalance: user?.ETHBalance || 0 });
    });
  };

  useEffect(() => {
    if (gameRef) {
      gameRef.events.emit('simulator-update-eth-balance', {
        address: user?.address || '',
        ETHBalance: user?.ETHBalance || 0,
      });
    }
  }, [user?.ETHBalance]);

  useEffect(() => {
    if (gameRef) {
      const index = leaderboardData.findIndex((item) => item.isUser);
      if (index > -1) {
        gameRef.events.emit('simulator-update-rank', { rank: index + 1 });
      }
    }
  }, [leaderboardData]);

  useEffect(() => {
    if (gameRef) {
      gameRef.events.emit('simulator-update-balances', { ...balances, ETHBalance: user?.ETHBalance || 0 });
      gameRef.events.emit('simulator-update-xtoken-balance', { balance: balances.xTokenBalance });
    }
  }, [balances, user?.ETHBalance]);

  useEffect(() => {
    if (gameRef) {
      gameRef.events.emit('simulator-update-networth', {
        networth: assets.networth,
        level: calculateHouseLevel(activeSeason?.houseLevels || [], assets.networth),
      });
    }
  }, [assets?.networth]);

  useEffect(() => {
    if (gameRef) {
      const now = Date.now();
      const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
      const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
      const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];

      gameRef.events.emit('simulator-update-machines', {
        numberOfMachines: assets.numberOfMachines,
        balance: user?.ETHBalance || 0,
        level: 0,
        dailyReward: activeSeason?.machine?.dailyReward,
        earningRateIncrementPerLevel: activeSeason?.machine?.earningRateIncrementPerLevel,
        basePrice: activeSeason?.machine.basePrice || 0,
        basePriceWhitelist: activeSeason?.machine.basePrice || 0,
        maxPerBatch: activeSeason?.machine.maxPerBatch || 0,
        dailyReward: activeSeason?.machine.dailyReward || 0,
        networthBase: activeSeason?.machine.networthBase,
        networthPerDay: activeSeason?.machine.networthPerDay,
        tokenPrice: market?.tokenPrice || 0,
        targetDailyPurchase: activeSeason?.machine.targetDailyPurchase,
        pricePower: activeSeason?.machine.pricePower,
        targetPrice: activeSeason?.machine.targetPrice,
        levelPower: activeSeason?.machine.levelPower,
        levelMultiple: activeSeason?.machine.levelMultiple,
        dailyWarCount: 24 / activeSeason?.warConfig?.warIntervalDurationInHours || 1,
        totalSold: activeSeason.machineSold + fakePurchases.numberOfMachineFakePurchases,
        days: gameDays,
        started: now > activeSeason.startTime.toDate().getTime(),
        startingPrice: activeSeason?.machine?.startingPrice || 0,
      });
    }
  }, [assets, user?.ETHBalance, activeSeason, market]);

  useEffect(() => {
    if (gameRef) {
      gameRef.events.emit('simulator-update-workers-machines', {
        numberOfWorkers: assets.numberOfWorkers,
        numberOfMachines: assets.numberOfMachines,
      });
    }
  }, [assets]);

  useEffect(() => {
    if (gameRef) {
      const now = Date.now();
      const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
      const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
      const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];
      gameRef.events.emit('simulator-update-workers', {
        numberOfWorkers: assets.numberOfWorkers,
        balance: balances.tokenBalance,
        sold: assets.numberOfWorkers,
        basePrice: activeSeason?.worker.basePrice,
        maxPerBatch: activeSeason?.worker.maxPerBatch,
        targetDailyPurchase: activeSeason?.worker.targetDailyPurchase,
        targetPrice: activeSeason?.worker.targetPrice,
        dailyReward: activeSeason?.worker.dailyReward,
        networthBase: activeSeason?.worker.networthBase,
        networthPerDay: activeSeason?.worker.networthPerDay,
        totalSold: activeSeason.workerSold + fakePurchases.numberOfWorkerFakePurchases,
        days: gameDays,
        started: now > activeSeason.startTime.toDate().getTime(),
        startingPrice: activeSeason?.worker?.startingPrice || 0,
      });
    }
  }, [assets, balances, activeSeason]);

  useEffect(() => {
    if (gameRef) {
      gameRef.events.emit('simulator-update-deposit-code', user?.code);
    }
  }, [user?.code]);

  useEffect(() => {
    if (gameRef) {
      const now = Date.now();
      const gameDays = (now - activeSeason.startTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
      const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
      const fakePurchases = purchaseConfig[`${Math.floor(minutes)}`] || purchaseConfig['default'];
      gameRef.events.emit('simulator-update-buildings', {
        numberOfBuildings: assets.numberOfBuildings,
        sold: assets.numberOfBuildings,
        basePrice: activeSeason?.building.basePrice,
        maxPerBatch: activeSeason?.building.maxPerBatch,
        targetDailyPurchase: activeSeason?.building.targetDailyPurchase,
        pricePower: activeSeason?.building?.pricePower,
        targetPrice: activeSeason?.building.targetPrice,
        networthBase: activeSeason?.building.networthBase,
        networthPerDay: activeSeason?.building.networthPerDay,
        totalSold: activeSeason.buildingSold + fakePurchases.numberOfBuildingFakePurchases,
        days: gameDays,
        started: now > activeSeason.startTime.toDate().getTime(),
        startingPrice: activeSeason?.building?.startingPrice || 0,
      });
    }
  }, [assets, activeSeason]);

  useEffect(() => {
    if (isLeaderboardModalOpen) {
      const { name, prizePoolEth, prizePoolToken, blastGoldReward, xUReward } = activeSeason || {};

      gameRef &&
        gameRef.events.emit('simulator-update-season', {
          name,
          prizePoolEth,
          prizePoolToken,
          blastGoldReward,
          xUPool: xUReward,
          isEnded,
        });
    }
  }, [
    isLeaderboardModalOpen,
    activeSeason?.name,
    activeSeason?.prizePoolEth,
    activeSeason?.prizePoolToken,
    activeSeason?.blastGoldReward,
    activeSeason?.xUReward,
    isEnded,
  ]);

  useEffect(() => {
    if (gameRef) {
      gameRef.events.emit('simulator-update-season-countdown', { countdownString, started, startTimeString });
    }
  }, [countdownString, started, startTimeString]);

  useEffect(() => {
    if (gameRef) {
      gameRef.events.emit('simulator-update-game-play', {
        numberOfMachines: assets.numberOfMachines,
        numberOfAvailableMachines: assets.numberOfAvailableMachines,
        numberOfWorkers: assets.numberOfWorkers,
        numberOfBuildings: assets.numberOfBuildings,
        ...assets.warDeployment,
        ...activeSeason?.warConfig,
      });
    }
  }, [
    assets.numberOfMachines,
    assets.numberOfAvailableMachines,
    assets.numberOfWorkers,
    assets.numberOfBuildings,
    activeSeason?.warConfig,
  ]);

  useEffect(() => {
    if (isLeaderboardModalOpen && gameRef) {
      gameRef.events.emit(
        'simulator-update-leaderboard-list',
        {
          users: { reputation: leaderboardData, raidPoints: [], uPoints: [] },
          userRecord: {
            reputation: leaderboardData[leaderboardData.length - 1],
            raidPoints: leaderboardData[leaderboardData.length - 1],
            uPoints: leaderboardData[leaderboardData.length - 1],
          },
          totalPages: {
            reputation: 1,
            raidPoints: 1,
            uPoints: 1,
          },
        } || []
      );
    }
  }, [isLeaderboardModalOpen, leaderboardData]);

  useEffect(() => {
    if (isLeaderboardModalOpen && gameRef)
      gameRef.events.emit('simulator-update-prize-pool-info', {
        prizePoolConfig: activeSeason?.prizePoolConfig,
        xUPool: activeSeason?.xUReward,
        blastGoldReward: activeSeason?.blastGoldReward,
      });
  }, [isLeaderboardModalOpen, activeSeason?.prizePoolConfig, activeSeason?.xUReward, activeSeason?.blastGoldReward]);

  useEffect(() => {
    if (gameRef)
      gameRef.events.emit('simulator-update-twitter-share-template', {
        template: templates.twitterShareReferralCode,
        referralCode: user?.referralCode || '',
      });
  }, [templates.twitterShareReferralCode, user?.referralCode]);

  return { setupSimulatorGameListener };
};

export default useSimulatorGameListener;

// helpers
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const mockUsers = Array.from({ length: 7 }, (_, index) => ({
  avatarURL: faker.internet.avatar(),
  id: faker.string.uuid(),
  networth: faker.number.int({ min: 0, max: 1000 }),
  uPoint: faker.number.int({ min: 0, max: 1000 }),
  username: faker.internet.userName(),
  active: true,
  rank: index + 1,
}));
